<template>
  <div :class="isMobile ?'m-transfer':'transfer'">
    <div :class="isMobile ? 'm-transfer-banner' : 'transfer-banner'">
      <img src="../assets/image/transfer/banner-bg.png" alt="">
      <div class="white" :class="isMobile ? 'm-carousel-item-text':'carousel-item-text'">
        <div :class="isMobile ? 'm-box-all':'box-all'">
          <div :class="isMobile ? 'm-box-foot':'box-foot'"></div>
        </div>
        <div :class="isMobile ? 'm-title font-family-AlimamaShuHeiTi':'font-family-AlimamaShuHeiTi title'">内外贸整合·优质国货·通商全球</div>
        <div :class="isMobile ? 'm-description':'description'">INTEGRATION OF DOMESTIC AND FOREIGN TRADE, HIGH-QUALITY DOMESTIC PRODUCTS, GLOBAL TRADE</div>
        <div :class="isMobile ? 'm-btn':'btn'" @click="goto"><el-button>开始转运<i class="el-icon-right margin-l-10"></i></el-button></div>
      </div>
      <div class="buying-service" v-if="!isMobile" @click="goto">
        <div class="round">
          <img src="../assets/image/transfer/fw-icon.png" alt="">
        </div>
        <p class="service">代买服务</p>
      </div>
    </div>
    <div :class="isMobile ? 'm-global':'global'">
      <div :class="isMobile ? 'm-global-title':'global-title'"><span style="color: #2A69FF">全球</span>布局 <span style="color: #2A69FF">为您</span>服务</div>
      <div :class="isMobile ? 'm-data':'data'">
        <ul>
          <li><p class="count">132<span class="unit">个</span></p><p class="desc">服务地区</p></li>
          <li><p class="count">326<span class="unit">亿</span></p><p class="desc">年吞吐量</p></li>
          <li><p class="count">300<span class="unit">条</span></p><p class="desc">运输路线</p></li>
        </ul>
        <div v-if="!isMobile" class="map-bg"><img src="../assets/image/transfer/map.png" alt=""></div>
        <div v-else class="m-map-bg"><img src="../assets/mobile-image/m-mao.png" alt=""></div>
        <div class="btn">
          <el-button @click="goto">成为小B采集</el-button>
          <el-button @click="goto" class="customization">定制化需求</el-button>
        </div>
      </div>
    </div>
    <div :class="isMobile ? 'm-good-share':'good-share'">
      <div :class="isMobile ? 'm-good-share-title':'good-share-title'">好物分享</div>
      <div :class="!isMobile?'good-share-box':'m-good-share-box'">
        <ul class="img-list-1">
            <li v-for="(item, index) in imageListA" :key="index">
              <img :src="item" alt="" v-if="item"/>
            </li>
        </ul>
        <ul class="img-list-2">
          <li v-for="(item, index) in imageListB" :key="index">
            <img :src="item" alt="" v-if="item"/>
          </li>
        </ul>
      </div>
    </div>
    <div :class="isMobile ? 'm-pit-warning':'pit-warning'">
      <div :class="isMobile ? 'm-pit-warning-title':'pit-warning-title'">避坑提醒</div>
      <div  :class="!isMobile ? 'pit-content': 'm-pit-content'">
        <ul>
          <li v-for="(item, index) in list" :key="index">
            <div class="avatar">
              <img :src="item.head_portrait" alt="" />
            </div>
            <div class="text-content">
              <div class="author">{{ item.nick_name }}</div>
              <div class="box">
                <div class="date" v-if="!isMobile">{{ item.date }}</div>
                <div class="grade">
                  <el-rate v-model="item.star" disabled text-color="#ff9900" score-template="{value}"></el-rate>
                </div>
              </div>
              <div class="desc" :alt="item.desc">{{ item.text }}</div>
            </div>
          </li>
        </ul>
      </div>
<!--      <div class="more" @click="more"> {{ !loading ? '查看更多' : 'Loading...' }}</div>-->
    </div>
  </div>
</template>

<script>
import axios from  'axios'
import { baseUrl } from '../utils/settings'
import { isMobileDevice } from '../utils/detectDevice';
export default {
  name: "transfer",
  data() {
    return {
      isMobile: isMobileDevice(),
      imageListA: [],
      imageListB: [],
      list: [],
      loading: false, // 控制loading状态
      num: 1,
    }
  },
  methods: {
    goto() {
      let routeUrl =  this.$router.resolve({ path: './coming-soon'})
      window.open(routeUrl.href, '_blank');
    },
    async getImg(){
      const response = await axios.get(`${baseUrl}/web/webapi/getHWFX`, this.formData);
      this.imageListA = response.data.data.A
      this.imageListB = response.data.data.B
    },
    async getList() {
      const response = await axios.get(`${baseUrl}/web/webapi/getBKTX`, this.formData);
      this.list = response.data.data
    },
    // 点击查看更多
    // more() {
    //   this.loading = true
    //   setTimeout(() => {
    //     const newData = Array.from({ length: 4 },(_, index) => ({
    //       id: this.list.length + index + 1,
    //       avatar: '', author: `Item ${this.list.length + index + 1}`, date: `Item ${this.list.length + index + 1}`, grade: 2, desc: '这里是一些过的用户评价，怎么避坑，怎么选品，什么问题之类的。文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案大概在三行到四行之间'
    //     }))
    //     this.list = [...this.list, ...newData];
    //     this.loading = false;
    //   }, 1000);
    // }
  },
  mounted() {
    this.getImg()
    this.getList()
  }
}
</script>

<style scoped lang="less">
.transfer {
  width: 100%;
  height: 100%;
  color: #102867;
  .transfer-banner {
    width: 100%;
    height: 960px;
    position: relative;
    img {
      width: 100%;
      height: 960px;
    }
    .carousel-item-text {
      position: absolute;
      top: 30%;
      left: 0;
      right: 0;
      box-sizing: border-box;
      text-align: center;
      width: 1280px;
      margin: 0 auto;
      .title {
        font-weight: 700;
      }
      .description {
        font-size: 30px;
        font-weight: 400;
        margin-top: 60px;
      }
      .box-all{
        width: 680px;
        height: 160px;
        border-top: 8px solid #ffffff;
        border-bottom: 8px solid #ffffff;
        z-index: 20;
        position: absolute;
        top: -7.5%;
        left: 50%;
        transform: translateX(-50%);
      }
      .box-all:before, .box-all:after{
        position:absolute;
        width: 0px;
        height: 15px;
        content: "";
        top: 0;
      }
      .box-all:before, .box-foot:before{
        border-left: 8px solid #ffffff;
        left: 0px;
      }
      .box-all:after,.box-foot:after{
        border-right: 8px solid #ffffff;
        right: 0;
      }
      .box-foot{
        position:absolute;
        bottom: 0;
        width: 100%;
        left: 0;
      }
      .box-foot:before, .box-foot:after{
        position:absolute;
        width: 0px;
        height: 15px;
        content: "";
        bottom: 0;
      }
      .btn .el-button--small {
        padding: 13px 28px;
        color: #102867;
        font-size: 20px;
        margin-top: 70px;
      }
      .btn .el-button:focus, .el-button:hover {
        border-color: white;
        background-color: white;
      }
    }
    .buying-service {
      width: 64px;
      height: 180px;
      background-color: white;
      position: absolute;
      right: 10%;
      bottom: 15%;
      border-radius: 32px 32px 32px 32px;
      cursor: pointer;
      .round {
        width: 48px;
        height: 48px;
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        img {
          width: 100%;
          height: 100%;
        }
      }
      .service {
        width: 16px;
        //height: 100%;
        font-size: 16px;
        font-weight: 600;
        color: #102867;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
     }

    }
  }
  .global {
    width: 100%;
    height: 960px;
    overflow: hidden;
    .global-title {
      width: 1280px;
      font: 60px AlimamaShuHeiTi;
      text-align: center;
      color: #121212;
      margin: 100px auto;
    }
    .data {
      ul {
        width: 1280px;
        height: 100px;
        margin: 0 auto;
        text-align: center;
        li {
          float: left;
          width: 400px;
          height: 100px;
          color: #102867;
          .count {
            font-size: 48px;
            .unit {
              font-size: 16px;
            }
          }
          .desc {
            color: #222222;
            font-size: 18px;
          }
        }
        li:nth-child(2) {
          margin: 0 40px;
        }
      }
      .map-bg {
        width: 1280px;
        height: 300px;
        margin:  70px auto;
        img {
          width: 100%;
          height: auto;
        }
      }
      .btn {
        text-align: center;
        display: flex;
        justify-content: center;
      }
      .btn .el-button--small {
        padding: 13px 28px;
        color: #ffffff;
        font-size: 20px;
      }
      .btn .el-button {
        width: 168px;
        height: 48px;
        background-color: #102867;
        border-color: #102867;
      }
      .customization{
        background-color: white!important;
        color: #102867!important;
        margin-left: 100px;
      }
    }
  }
  .good-share {
    width: 100%;
    height: 960px;
    background: url("../assets/image/transfer/share-bg.png") no-repeat center center;
    background-size: 100% 100%;
    overflow: hidden;
    .good-share-title {
      font: 60px AlimamaShuHeiTi;
      font-weight: 700;
      color: #121212;
      text-align: center;
      margin: 100px auto;
      width: 1280px;
    }
    .good-share-box {
      width: 100%;
      height: 460px;
      position: relative;
      margin: 0 auto;
      .img-list-1 {
        width: 10000%;
        height: 200px;
        position: absolute;
        left: -10%;
        top: 0;
        overflow: hidden;
        li {
          width: 320px;
          height: 200px;
          border-radius: 10px;
          margin: 0 20px;
          display: inline-flex;
          flex-direction: row;
          animation: leftMove 10s linear infinite;
          img {
            width: 100%;
            height: auto;
            border-radius: 10px;
          }
        }
      }
      .img-list-2 {
        width: 10000%;
        height: 200px;
        position: absolute;
        bottom: 0;
        left: 0;
        overflow: hidden;
        li {
          width: 320px;
          height: 200px;
          border-radius: 10px;
          margin: 0 20px;
          display: inline-flex;
          flex-direction: row;
          animation: leftMove 10s linear infinite;
          img {
            width: 100%;
            height: auto;
            border-radius: 10px;
          }
        }
      }
    }
  }
  .pit-warning {
    width: 100%;
    height: 960px; //有更多按钮设置100%
    overflow: hidden;
    .pit-warning-title {
      font: 60px AlimamaShuHeiTi;
      font-weight: 700;
      color: #121212;
      text-align: center;
      margin: 100px auto;
      width: 1280px;
    }
    .pit-content {
      width: 1280px;
      height: 520px; //有更多按钮设置100%
      margin: 0 auto;
      ul {
        width: 100%;
        height: 100%;
        li {
          width: 50%;
          height: 50%;
          color: white;
          display: inline-flex;
          flex-direction:row;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: flex-start;
          margin-bottom: 50px;
          .avatar {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            margin-right: 30px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .text-content {
            width: calc(100% - 200px);
            height: 100%;
            .author {
              color: #000000;
              font-size: 20px;
              font-weight: 400;
            }
            .box {
              display: flex;
              justify-content: space-between;
              margin: 15px 0;
              .date {
                font-size: 16px;
                color: #777777;
              }
            }
            .desc {
              width: 100%;
              height: 100px;
              font-size: 16px;
              color: #11142C;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              overflow: hidden;
              -webkit-line-clamp: 4; /* 控制显示的行数 */
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
    .more {
      font-size: 14px;
      color: #11142C;
      text-decoration: underline;
      cursor: pointer;
      width: 100%;
      height: 20px;
      display: flex;
      justify-content: center;
      text-align: center;
      margin: 50px auto;
    }
  }
}
//移动端
.m-transfer {
  width: 100vw;
  color: #102867;
  .m-transfer-banner {
    width: 100%;
    height: 5rem;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .m-carousel-item-text {
      position: absolute;
      top: 30%;
      left: 0;
      right: 0;
      box-sizing: border-box;
      text-align: center;
      margin: 0 .8rem;
      .m-box-all{
        width: 4.2rem;
        height: 1rem;
        border-top: .05rem solid #ffffff;
        border-bottom: .05rem solid #ffffff;
        z-index: 20;
        position: absolute;
        top: -7.5%;
        left: 50%;
        transform: translateX(-50%);
      }
      .m-box-all:before, .m-box-all:after{
        position:absolute;
        width: 0px;
        height: .12rem;
        content: "";
        top: 0;
      }
      .m-box-all:before, .m-box-foot:before{
        border-left: .05rem solid #ffffff;
        left: 0px;
      }
      .m-box-all:after,.m-box-foot:after{
        border-right: .05rem solid #ffffff;
        right: 0;
      }
      .m-box-foot{
        position:absolute;
        bottom: 0;
        width: 100%;
        left: 0;
      }
      .m-box-foot:before, .m-box-foot:after{
        position:absolute;
        width: 0px;
        height: .12rem;
        content: "";
        bottom: 0;
      }
      .m-title {
        font-size: .5rem;
        font-family: AlimamaShuHeiTi;
      }
      .m-description {
        font-size: .2rem;
        margin-top: .25rem;
      }
      .m-btn .el-button--small {
        padding: .2rem 0.33rem .19rem .33rem;
        color: #102867;
        font-size: 0.28rem;
        background-color: #fff;
        display: block;
        margin: .3rem auto 0 auto;
      }
      .m-btn .el-button:focus, .el-button:hover {
        border-color: white;
        background-color: white;
      }
    }
  }
  .m-global {
    width: 100vw;
    height: 7.5rem;
    .m-global-title {
      width: 100%;
      font: .55rem AlimamaShuHeiTi;
      color: #121212;
      text-align: center;
      margin: .5rem auto .7rem auto;
    }
    .m-data {
      width: 100vw;
      ul {
        width: 100vw;
        height: 1.5rem;
        text-align: center;
        li {
          float: left;
          width: 33.33%;
          .count {
            font-size: .55rem;
            color: #102867;
            .unit {
              font-size: .24rem;
            }
          }
          .desc {
            font-size: .26rem;
            color: #222222;
          }
        }
      }
      .m-map-bg {
        width: 95%;
        height: 3rem;
        margin:0 auto;
        display: flex;
        align-items: center;
        img {
          width: 100%;
          height: auto;
        }
      }
      .btn {
        text-align: center;
        .el-button--small {
          padding: .2rem 0.33rem .19rem .33rem;
          color: #ffffff;
          font-size: 0.28rem;
        }
        .el-button {
          background-color: #102867;
          border-color: #102867;
        }
        .customization{
          background-color: white!important;
          color: #102867!important;
          margin-left: .6rem;
        }
      }
    }

  }
  .m-good-share {
    width: 100vw;
    height: 8rem;
    background: url("../assets/image/transfer/share-bg.png") no-repeat center center;
    background-size: cover;
    overflow: hidden;
    .m-good-share-title {
      font: .55rem AlimamaShuHeiTi;
      font-weight: 700;
      color: #121212;
      text-align: center;
      margin: .5rem auto;
    }
    .m-good-share-box {
      width: 100vw;
      height: 5.4rem;
      position: relative;
      margin: 0 auto;
      .img-list-1 {
        width: 100000%;
        height: 2.5rem;
        position: absolute;
        left: -10%;
        top: 0;
        overflow: hidden;
        li {
          width: 3.6rem;
          height: 2.5rem;
          border-radius: 5px;
          margin: 0 .2rem;
          display: inline-flex;
          flex-direction: row;
          animation: leftMove 10s linear infinite;
          img {
            width: 100%;
            height: auto;
            border-radius: 5px;
          }
        }
      }
      .img-list-2 {
        width: 1000000000%;
        height: 2.5rem;
        position: absolute;
        bottom: 0;
        left: 0;
        overflow: hidden;
        li {
          width: 3.6rem;
          height: 2.5rem;
          border-radius: 5px;
          background-color: #102867;
          margin: 0 .2rem;
          display: inline-flex;
          flex-direction: row;
          animation: leftMove 10s linear infinite;
          img {
            width: 100%;
            height: auto;
            border-radius: 5px;
          }
        }
      }
    }
  }
  .m-pit-warning {
    width: 100vw;
    height: 7.7rem;
    overflow: hidden;
    .m-pit-warning-title {
      font: .55rem AlimamaShuHeiTi;
      font-weight: 700;
      color: #121212;
      text-align: center;
      margin: .5rem auto;
    }
    .m-pit-content {
      width: 100vw;
      height: 6rem;
      ul {
        width: 100%;
        height: 100%;
        padding: 0 .3rem;
        li {
          width: 50%;
          height: 50%;
          color: white;
          display: inline-flex;
          flex-direction:row;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: flex-start;
          .avatar {
            width: .8rem;
            height: .8rem;
            border-radius: 50%;
            margin-right: .3rem;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .text-content {
            width: calc(100% - 1.4rem);
            height: 100%;
            .author {
              color: #000000;
              font-size: .35rem;
              font-weight: 400;
            }
            .box {
              //display: flex;
              //justify-content: space-between;
              margin: .1rem 0;
              //.date {
              //  font-size: .28rem;
              //  color: #777777;
              //}
              .grade {
                ::v-deep .el-rate {
                  height: .3rem;
                  line-height: .3rem;
                }
                ::v-deep .el-rate__icon {
                  font-size: .28rem;
                }
              }
            }
            .desc {
              width: 100%;
              height: 1.1rem;
              font-size: .24rem;
              color: #11142C;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              overflow: hidden;
              -webkit-line-clamp: 3; /* 控制显示的行数 */
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
@keyframes leftMove {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%); /* 使用负值来实现循环移动 */
  }
}
</style>
